import { Injectable } from "@angular/core";
import { User } from "../models/user.model";
import { AuthHelper } from "./auth-helper";
import { UserAccessType } from "../models/user-access-type.model";
import { Roles } from "./Roles";

@Injectable({ providedIn: 'root' })
export class AuthPolicies {
    loggedinUser: User | null = AuthHelper.user;
    get canCreateWorkOrder(): boolean {
        if (this.loggedinUser) {
            return this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.SHOP || role.toLowerCase() === Roles.ADMIN)
        }
        else {
            return false;
        }
    }
    get canMarkWorkOrderAsTTL(): boolean {
        if (this.loggedinUser) {
            return this, this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.TTLAPPROVER)
        } else {
            return false;
        }
    }

    get canEditWorkOrder(): boolean {
        if (this.loggedinUser) {
            return this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.SHOP)
        }
        else {
            return false;
        }
    }

    get canViewAuditLog(): boolean {
        if (this.loggedinUser) {
            return this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.APPROVER
                || role.toLowerCase() === Roles.ADMIN
                || role.toLowerCase() === Roles.MANAGER
                || role.toLowerCase() === Roles.SPECIALIST
                || role.toLowerCase() === Roles.TTLAPPROVER)
        }
        else {
            return false;
        }
    }
    get canForwardWorkOrder(): boolean {
        if (this.loggedinUser) {
            return this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.APPROVER)
        }
        else {
            return false;
        }
    }
    get canHoldWorkOrder(): boolean {
        if (this.loggedinUser) {
            return (this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.APPROVER)
                || this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.TTLAPPROVER) ||
                this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.SPECIALIST) ||
                this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.MANAGER)
            )
        }
        else {
            return false;
        }
    }

    get approverRole(): boolean {

        if (this.loggedinUser) {
            return this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.APPROVER)
        }
        else {
            return false;
        }
    }

    get managerRole(): boolean {

        if (this.loggedinUser) {
            return this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.MANAGER)
        }
        else {
            return false;
        }
    }
    get specialistRole(): boolean {

        if (this.loggedinUser) {
            return this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.SPECIALIST)
        }
        else {
            return false;
        }
    }
    get canViewManualWorkOrder(): boolean {
        if (this.loggedinUser) {
            return (this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.APPROVER)
                || this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.TTLAPPROVER) ||
                this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.SPECIALIST) ||
                this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.MANAGER) ||
                this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.ADMIN)
            )
        }
        else {
            return false;
        }
    }
    get canViewApprovalSettings(): boolean {
        if (this.loggedinUser) {
            return (this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.ADMIN))
        }
        else {
            return false;
        }
    }

    get isAppSupportAdmin(): boolean {
        if (this.loggedinUser) {
            return (this.loggedinUser.roles.some(role => role.toLowerCase() === Roles.APP_SUPPORT_ADMIN))
        }
        else {
            return false;
        }
    }

    static get userAccessType() {
        const loggedinUserRoles: string[] | undefined = AuthHelper.user?.roles;
        const view: UserAccessType = loggedinUserRoles?.find(x => x.toLowerCase() === 'shop') ? UserAccessType.Vendor : UserAccessType.GSC;
        return view;
    }
}
 