export interface MaintenanceOrderStatus {
    code?: MaintenanceOrderStatusCode;
    description: string;
    generatedCode?: string;
}

export enum MaintenanceOrderStatusCode
{
    Rejected = 100,
    DeclaredAsTTL = 150,
    PendingApproval = 200,
    PendingSpecialistApproval = 330,
    SuspendForTTLCheck = 340,
    SuspendToCENELM = 320,
    Approved = 390,
    RepairCompleted = 400,
    PurchaseOrderInitiated = 500,
    PurchaseOrderFailed = 550,
    PurchaseOrderCreated = 600,
    PaymentFailed = 650,
    PaymentCompleted = 800,
    Deleted = 9999,
    Forward=330,  
    Processed = 900,
   
}